import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5d6d23c9"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ScssInit: require('/root/containers/hermes_eshop/components/core/scssInit.vue').default,DesignSystem: require('/root/containers/hermes_eshop/components/core/designSystem.vue').default,Transitioner: require('/root/containers/hermes_eshop/components/core/transitioner.vue').default,Pending: require('/root/containers/hermes_eshop/components/core/pending.vue').default,Preview: require('/root/containers/hermes_eshop/components/core/preview.vue').default,SideMenuDynamic: require('/root/containers/hermes_eshop/components/core/sideMenu_dynamic.vue').default,SideMenuDynamicDesaturated: require('/root/containers/hermes_eshop/components/core/sideMenu_dynamic_desaturated.vue').default,ElementBuilder: require('/root/containers/hermes_eshop/components/core/elementBuilder.vue').default,Cookies: require('/root/containers/hermes_eshop/components/core/cookies.vue').default,Iordanidhs: require('/root/containers/hermes_eshop/components/core/iordanidhs.vue').default,OptionsRequired: require('/root/containers/hermes_eshop/components/core/optionsRequired.vue').default,AddToCart: require('/root/containers/hermes_eshop/components/core/addToCart.vue').default,ContactForm: require('/root/containers/hermes_eshop/components/core/contactForm.vue').default,Speaking: require('/root/containers/hermes_eshop/components/core/speaking.vue').default,Specific: require('/root/containers/hermes_eshop/components/core/specific.vue').default})
